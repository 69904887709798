<template>
	<div id="footer">
		<!-- <div class="bubbles">
			<div
				class="bubble"
				style="--size:2.4559311599733995rem; --distance:30.227600719293314rem; --position:62.62259127479484%; --time:2.721953860748526s; --delay:-3.615248376660494s;--colors:#007fce"
			></div>
			<div
				class="bubble"
				style="--size:9.126099203217152rem; --distance:11.579481092427914rem; --position:66.44791178748683%; --time:3.521433133289932s; --delay:-7.585508775545236s;--colors:#007fce"
			></div>
			<div
				class="bubble"
				style="--size:3.5653398439190056rem; --distance:17.714269786916766rem; --position:72.58326742011728%; --time:3.6952886472650297s; --delay:-2.1664448151243345s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:2.6412704543944585rem; --distance:26.446521250534744rem; --position:23.437067254763505%; --time:5.5625873622024775s; --delay:-4.15632289150482s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:9.666312411323844rem; --distance:34.44403672867267rem; --position:76.46681642145167%; --time:4.934624154820534s; --delay:-6.411024958929172s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:4.859024162437144rem; --distance:7.942275438354937rem; --position:37.838967615168016%; --time:4.211068138874969s; --delay:-2.2789175503284445s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:6.085702929257588rem; --distance:28.524145006035667rem; --position:5.494315071290021%; --time:4.763870353091639s; --delay:-5.172592043143872s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:7.290070333991922rem; --distance:13.896082395837558rem; --position:73.87539308738766%; --time:4.517126299057501s; --delay:-3.666785496384729s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:4.154337302667143rem; --distance:43.40000151983389rem; --position:79.97592588376592%; --time:2.090427926043967s; --delay:-2.0882789857305957s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:8.897028009536331rem; --distance:33.01861690553422rem; --position:71.79220912855045%; --time:4.044731595276701s; --delay:-6.60357733681286s;--colors:#007fce"
			></div>
			<div
				class="bubble"
				style="--size:8.01282611865355rem; --distance:36.36269752738188rem; --position:82.05983714362979%; --time:3.479748448859926s; --delay:-7.033544659143445s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:2.1197924160176402rem; --distance:31.216948946033668rem; --position:-2.975811478819783%; --time:2.117271250372368s; --delay:-6.03069829677448s;--colors:#007fce"
			></div>
			<div
				class="bubble"
				style="--size:4.471457267984482rem; --distance:9.156664250213002rem; --position:66.61033044213326%; --time:4.459849005424321s; --delay:-6.389584489883213s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:4.321149029130902rem; --distance:6.971768338171222rem; --position:3.30690172879709%; --time:2.6631890459210332s; --delay:-2.259592924524197s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:6.885506924680076rem; --distance:29.4481373822413rem; --position:36.02645214017093%; --time:4.329069360486101s; --delay:-2.7616327454148037s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:7.46121930818032rem; --distance:41.876020093764026rem; --position:-0.8148285434487441%; --time:3.0718162858692493s; --delay:-5.111739251014594s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:7.33105261470433rem; --distance:10.257561199878168rem; --position:46.74766685536063%; --time:4.082535768437733s; --delay:-2.416217348727313s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:4.828571272181049rem; --distance:15.12117408391718rem; --position:38.74897599759937%; --time:5.020599617161098s; --delay:-3.9942445090414473s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:6.920535746714775rem; --distance:29.223240102662004rem; --position:77.7903818713684%; --time:4.3259815778744475s; --delay:-5.918524155129162s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:5.844814450358159rem; --distance:28.357568742149354rem; --position:71.80522874797214%; --time:4.313143613601635s; --delay:-5.737063753590663s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:5.955129100701059rem; --distance:14.148969877076429rem; --position:37.7205321788757%; --time:4.815745818591805s; --delay:-7.869179166443859s;--colors:#007fce"
			></div>
			<div
				class="bubble"
				style="--size:8.182762153818395rem; --distance:24.267228838196257rem; --position:19.37338303553673%; --time:3.3744961736558405s; --delay:-5.056391166803731s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:2.175050332938996rem; --distance:36.29214485106003rem; --position:8.972483891126995%; --time:3.572298293468963s; --delay:-4.898835817430571s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:9.956512730295303rem; --distance:33.592160735843734rem; --position:43.30148578943996%; --time:2.2023092303447873s; --delay:-2.762242063159009s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:3.8716684276543614rem; --distance:20.568013742260206rem; --position:47.785679665050324%; --time:2.0134653072279898s; --delay:-6.124394337656872s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:9.697304555913755rem; --distance:34.21048157328008rem; --position:-1.457067060753241%; --time:5.389299106711135s; --delay:-4.715766403314503s;--colors:#007fce"
			></div>
			<div
				class="bubble"
				style="--size:6.890593181066645rem; --distance:15.371227818575036rem; --position:3.487783357117218%; --time:5.9313201164469245s; --delay:-2.9210260648654565s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:4.312442985153188rem; --distance:20.045683324349582rem; --position:58.22605700316065%; --time:3.5754359045548076s; --delay:-2.752986883214795s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:7.701233625861812rem; --distance:12.896289812638097rem; --position:78.93526209687752%; --time:2.4605888722509155s; --delay:-4.702818037694741s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:4.343181396427083rem; --distance:43.10925267178622rem; --position:-2.5968519791377176%; --time:2.274738777887359s; --delay:-7.790092379679385s;--colors:#f5bd00"
			></div>
			<div
				class="bubble"
				style="--size:7.485531468874219rem; --distance:6.007983785609129rem; --position:76.97974739616964%; --time:4.032433232655146s; --delay:-4.625062303343063s;--colors:#ff0e58"
			></div>
			<div
				class="bubble"
				style="--size:7.395052242958421rem; --distance:32.77923484884705rem; --position:84.64841575823483%; --time:2.925765243616892s; --delay:-4.524905453842674s;--colors:#ff0e58"
			></div>
		</div> -->
		<div class="content">
			<!-- <div>
				<div><b>E-mail:</b><a href="#">crazyforcode@qq.com</a></div>
				<div><b>2022 QQ招新群:</b><a href="#">981428044</a></div>
			</div> -->
			<div id="footBox">
				<img src="../assets/FootImg/CFC.png" alt="#" id="cfcLogo" />
				<div id="icons">
					<div @click="onQQClick">
						<img src="../assets/FootImg/qq.png" alt="" class="iconImg" />
					</div>
					<a href="https://github.com/CrazyForCode"
						><img src="../assets/FootImg/github.png" alt="" class="iconImg" />
					</a>
					<a
						href="https://mp.weixin.qq.com/s?__biz=Mzg3NzUxNjE5OQ==&mid=2247483698&idx=1&sn=9e63c934c697a21665b4c8f7e736b663&chksm=cf2085faf8570cec13b773d84bcd80c8e6d1574fe7c5b343982ad0bd9381806b896a017dd634&mpshare=1&scene=23&srcid=1124z7zoWyC7pMy1QLQK5pzr&sharer_sharetime=1637759221463&sharer_shareid=502450450a38379b9390fed83b301578%23rd"
						><img src="../assets/FootImg/wechat.png" alt="" class="iconImg" />
					</a>
					<!-- <a href="#"><img src="../assets/FootImg/email.png" alt="" class="iconImg" /> </a> -->
				</div>
				<p id="footText" class="text">©2013-2021 Not Really</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	methods: {
		onQQClick() {
			alert('加入2022 QQ招新群：981428044 了解更多')
		}
	}
}
</script>

<style lang="scss" scoped>
#footer {
	z-index: 1;
	--footer-background: #4acac3;
	display: grid;
	position: relative;
	grid-area: footer;
	min-height: 300px;
	.bubbles {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 150px;
		background: var(--footer-background);
		// filter:url("#blob");
		.bubble {
			position: absolute;
			// bottom: 100px;
			left: var(--position, 50%);
			background: var(--colors);
			border-radius: 100%;
			animation: bubble-size var(--time, 6s) ease-in-out infinite
					var(--delay, 0s),
				bubble-move var(--time, 8s) ease-in-out infinite var(--delay, 0s);
			transform: translate(-50%, 100%);
		}
	}
	.content {
		z-index: 2;
		display: flex;
		justify-content: space-around;
		align-content: center;
		flex-direction: row;
		align-items: center;
		background: var(--footer-background);
	}
}
#footBox {
	display: flex;
	flex-direction: column;
	align-items: center;
}
#footText {
	color: white;
	font-weight: 600;
	width: fit-content;
	padding-top: 5px;
}

@keyframes bubble-size {
	0%,
	55% {
		width: var(--size, 1rem);
		height: var(--size, 1rem);
	}
	100% {
		width: 6rem;
		height: 6rem;
	}
}
@keyframes bubble-move {
	0% {
		bottom: 0rem;
	}
	100% {
		bottom: var(--distance, 10rem);
	}
}
#cfcLogo {
	height: 80px;
	width: auto;
	border-bottom: solid 3px white;
	padding-bottom: 5px;
	padding-right: 5px;
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray;
}
#icons {
	width: 205px;
	height: auto;
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	align-content: center;
}
.iconImg {
	width: 25px;
	height: 25px;
}
</style>
